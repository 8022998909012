@import "../../../data/styles.css";


.works{
	display: flex;
	left: 0;
	right: 0;
	justify-content: flex-start;
}

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	padding-bottom: 45px;
	width: auto;
}

.work-image {
	height: 42px;
	width: 42px;
	border-radius: 20%;
	outline: 6px solid white;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -6px;
	color: var(--secondary-color);
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 17px;
	padding-left: 63px;
}

.work-duration {
	position: absolute;
	width: 120px;
	padding-left: 76px;
	font-size: 12px;
	padding-top: 36px;
	text-align: left;
	color: var(--tertiary-color);
}
@media(max-width: 900px){
	.works{
		flex-direction: column;
	}
}