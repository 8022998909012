.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 80px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 300px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 900px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	/* transform: rotate(0deg); */
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}


.about-study {
	width: auto;
	padding-top: 30px;
}

.about-proglang-list{
	padding: 0;
	margin-left: 20px;
}
.about-proglang-list-ele{
	margin-top: 4px; /* Adjust this value to shift the icons down */
	display: flex;
    align-items: center;
}

.about-proglang-list-ele svg {
	transform: translate(0px, -2px);
	margin-right: 5px; /* Add some spacing between the icon and text */
}

@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: row;
	}
	.about-image-container {
		width: 100px;
	}

	.about-subtitle {
		padding-top: 20px !important;
	}

	.about-left-side {
		flex-basis: 80%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 20%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}


	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
	
}
