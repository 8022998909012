@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0vh;
	z-index: 999;
}

.nav-background {
	width: 100%;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: rgb(221, 251, 209);
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.438);
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	margin-right: 8px;
	margin-left: 8px;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		font-size: 100%;
	}

	.nav-background {
		width: 100%;
		height: 40px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	.nav-item {
		font-weight: bold;
	}
}
