:root {
	/* ------- colors ------- */
	--primary-color: #18181a;
	--secondary-color: #414146;
	--tertiary-color: #727278;
	--quaternary-color: #e4e4e7;
	--link-color: #00a319;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}
